import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import classes from "./MultiCarousel.module.scss"
import CustomButtonGroup from "src/components/Carousel/MultiCarousel/components/CustomButtonGroup/CustomButtonGroup"
import clsx from "clsx"

const MultiCarousel = ({
  setup,
  children,
  sectionTitle,
  className,
  buttonGroup,
}) => {
  return (
    <div className={clsx(classes.multiCarousel, className)}>
      <div
        className={buttonGroup ? classes.container : `${classes.container} p-0`}
      >
        <Carousel
          swipeable={true}
          draggable={false}
          responsive={setup}
          containerClass={
            buttonGroup
              ? classes.carousel
              : `${classes.carousel} ${classes["carousel__mini"]}`
          }
          arrows={false}
          itemClass={
            buttonGroup
              ? classes.itemCarousel
              : `${classes.itemCarousel} ${classes["itemCarousel__mini"]}`
          }
          customButtonGroup={
            <CustomButtonGroup
              sectionTitle={sectionTitle}
              buttonGroup={buttonGroup}
            />
          }
          renderButtonGroupOutside={buttonGroup}
        >
          {children}
        </Carousel>
      </div>
    </div>
  )
}

export default MultiCarousel
