import classes from "src/components/Carousel/MultiCarousel/MultiCarousel.module.scss"
import clsx from "clsx"
import SvgIcon from "src/components/SvgIcon"
import arrowLeftBlack from "src/assets/icons/arrow-prev.svg"
import arrowRightYellow from "src/assets/icons/arrow-next.svg"
import React from "react"

const CustomButtonGroup = ({
  sectionTitle,
  next,
  previous,
  buttonGroup,
  carouselState: { currentSlide, totalItems, slidesToShow },
}) => {
  return (
    <div
      className={
        buttonGroup
          ? classes.arrowsGroup
          : `${classes.arrowsGroup} ${classes.arrowsGroupIn}`
      }
    >
      <h3 className={classes.title}>{sectionTitle}</h3>
      <div className={buttonGroup ? "" : classes.buttonIn}>
        <button
          className={clsx(classes.arrowBtn, {
            [classes["arrowBtn__active"]]: currentSlide !== 0,
          })}
          onClick={previous}
        >
          <SvgIcon icon={arrowLeftBlack} />
        </button>
        {buttonGroup ? null : (
          <div className={classes.countSlidesWrapper}>
            <span className={classes.countSlides}>
              {currentSlide < 9 ? `0${currentSlide + 1}` : currentSlide + 1}
              {" - "}
              {totalItems < 10 ? `0${totalItems}` : totalItems}
            </span>
          </div>
        )}
        <button
          className={clsx(classes.arrowBtn, {
            [classes["arrowBtn__active"]]:
              currentSlide < totalItems - slidesToShow,
          })}
          onClick={next}
        >
          <SvgIcon icon={arrowRightYellow} />
        </button>
      </div>
    </div>
  )
}
export default CustomButtonGroup
