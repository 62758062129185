import React, { memo, useContext, useMemo } from "react"
import Marquee from "react-fast-marquee"
import "./TikerOverride.scss"
import classes from "./Ticker.module.scss"
import { FormOpenContext } from "../BasicLayout/BasicLayout"
import HaveIdea from "./components/HaveIdea/HaveIdea"

const TickerRun = ({ text, yellow = true, stringTicker, className, none }) => {
  const { handleOpenContactForm } = useContext(FormOpenContext)
  const isOpenCV = text.includes("Send CV")

  const title = useMemo(() => {
    return text === "Get in Touch"
      ? "Do You</br> Have an Idea?"
      : "Like</br> What You See?"
  }, [text])

  return (
    <div className={classes.root}>
      {stringTicker && (
        <div
          id={text.replace(/\s/g, "")}
          className={classes.tickerContainer}
          onClick={
            isOpenCV
              ? () => handleOpenContactForm(true)
              : () => handleOpenContactForm(false)
          }
        >
          <Marquee
            gradient={false}
            pauseOnHover={true}
            speed={40}
            className={
              yellow
                ? `${classes.tickerDefault} ${classes.tickerYellow}`
                : classes.tickerDefault
            }
            loop={0}
          >
            <div
              className={
                yellow
                  ? `${classes.tickerItem} ${classes.tickerItemYellow}`
                  : classes.tickerItem
              }
            >
              {text}
            </div>
            <div
              className={
                yellow
                  ? `${classes.tickerItem} ${classes.tickerItemYellow}`
                  : classes.tickerItem
              }
            >
              {text}
            </div>
            <div
              className={
                yellow
                  ? `${classes.tickerItem} ${classes.tickerItemYellow}`
                  : classes.tickerItem
              }
            >
              {text}
            </div>
            <div
              className={
                yellow
                  ? `${classes.tickerItem} ${classes.tickerItemYellow}`
                  : classes.tickerItem
              }
            >
              {text}
            </div>
            <div
              className={
                yellow
                  ? `${classes.tickerItem} ${classes.tickerItemYellow}`
                  : classes.tickerItem
              }
            >
              {text}
            </div>
          </Marquee>
        </div>
      )}
      <HaveIdea
        isOpenCV={false}
        title={title}
        text={text}
        className={className}
        none={none}
      />
    </div>
  )
}

export default memo(TickerRun)
